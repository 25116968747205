import { Nullable } from '../typings';

export type NotificationType =
  | 'default'
  | 'success'
  | 'info'
  | 'warning'
  | 'error';

export type Notifier = (
  message: string,
  type?: NotificationType,
) => void | Promise<void>;

let notifierInstance: Nullable<Notifier> = null;

export function setupNotifier(notifier: Nullable<Notifier>): void {
  notifierInstance = notifier;
}

export function notify(
  message: string,
  type?: NotificationType,
): void | Promise<void> {
  if (!notifierInstance) throw new Error('Missing notifier');

  return notifierInstance(message, type);
}
